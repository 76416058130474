export function createuUuid (value: string): string {
  value = String(value || "").trim().replace(/-| /g, "")
  let finalValue = ""

  for (const i of value) {
    if (Math.floor(Math.random() * (1 - 0 + 1)) + 0) finalValue += i
  }

  return finalValue
}
