import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#77ABFF",
        secondary: "#88C8FE",
        accent: "#60B7FE",
        border: "#4187B5",
        text: "#4288b6",
      },
    },
    icons: {
      iconfont: "md",
    },
  }
});
